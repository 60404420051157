import messages from './lang/messages';
import { helperCurrentLang } from './helpers/index';

export function register() {
  const lang = helperCurrentLang();

  if ('serviceWorker' in window.navigator) {
    window.addEventListener('load', () => {
      window
        .navigator
        .serviceWorker
        .register('/serviceworker.js')
        .then((registration) => {
          console.log('sw registered');

          registration.onupdatefound = () => {
            console.log('an update found for sw');
            const installingWorker = registration.installing;
            if (installingWorker == null) {
              return;
            }
            console.log('new sw is installing...');
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (window.navigator.serviceWorker.controller) {
                  window.alertModal(messages[lang]['there_is_an_update'], 'reload-modal');
                  console.log(messages[lang]['there_is_an_update']);
                } else {
                  console.log('content is cached for offline use');
                }
              }
            };
          };
        })
        .catch((error) => {
          console.error('error registering sw', error);
        });

      window
        .document
        .addEventListener('codebase_updated', (evt) => {
          window
            .navigator
            .serviceWorker
            .ready
            .then((registration) => {
              if (registration) {
                console.log('updating sw...');
                registration.update();
              }
            });
        });

      const previousServiceWorker = window.navigator.serviceWorker.controller;
      window
        .navigator
        .serviceWorker
        .addEventListener('controllerchange', () => {
          if (previousServiceWorker) {
            // This is due to an update, reload the page so that new sw takes control.
            window.location.reload();
          } else {
            // This is due to a SW taking control for the first time, no need to reload the page.
          }
        });
    });
  }
}

export function unregister() {
  if ('serviceWorker' in window.navigator) {
    window
      .navigator
      .serviceWorker
      .ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

export function reloadAfterUpdate() {
  if ('serviceWorker' in window.navigator) {
    window
      .navigator
      .serviceWorker
      .ready
      .then((registration) => {
        if (registration.waiting && registration.waiting.state === 'installed') {
          registration.waiting.postMessage({
            type: 'skip_waiting',
          });
        }
      });
  }
}
