const uz = {
  'noscript_warning': 'Ushbu ilovani ishga tushirish uchun JavaScriptni yoqishingiz kerak.',
  'company_name': '"' + process.env.REACT_APP_GLOBAL_COMPANY_NAME + '" MChJ',
  'company_address': '111204, O\'zbekiston Respublikasi, Toshkent vil., Qibray tum., A. Navoiy MFY, A. Navoiy ko\'chasi, 4',
  'attribute-photo': 'Fotosurat',
  'attribute-username': 'Foydalanuvchi nomi',
  'attribute-first_name': 'Ism',
  'attribute-last_name': 'Familiya',
  'attribute-middle_name': 'Sharif',
  'attribute-email': 'Elektron pochta',
  'attribute-date_of_birth': 'Tug\'ilgan sana',
  'attribute-city': 'Shahar',
  'attribute-address': 'Manzil',
  'attribute-phone': 'Mobil telefon raqami',
  'attribute-role': 'Rol',
  'attribute-password': 'Maxfiy so\'z',
  'attribute-message': 'Xabar',
  'attribute-thread': 'Mavzu',
  'attribute-subject': 'Mavzu',
  'attribute-content': 'Kontent',
  'attribute-title': 'Nom',
  'attribute-hour': 'Soat',
  'attribute-minute': 'Daqiqa',
  'attribute-second': 'Soniya',
  'attribute-name': 'Foydalanuvchi nomi',
  'attribute-slug': 'Slag',
  'attribute-description': 'Tavsif',
  'attribute-path': 'Yo\'l',
  'attribute-title.uz': 'Sarlavha (uz)',
  'attribute-title.ru': 'Sarlavha (ru)',
  'attribute-title.en': 'Sarlavha (en)',
  'attribute-slug.uz': 'Slag (uz)',
  'attribute-slug.ru': 'Slag (ru)',
  'attribute-slug.en': 'Slag (en)',
  'attribute-path.*': 'Yo\'l',
  'attribute-sorting_order': 'Saralash tartibi',
  'attribute-image': 'Rasm',
  'attribute-uploadFiles.*': 'yuklangan fayl',
  'attribute-locale': 'Til',
  'attribute-publish_date': 'Nashr sanasi',
  'attribute-status': 'Holat',
  'attribute-template': 'Shablon',
  'attribute-post_type': 'Xabar turi',
  'attribute-published_date': 'Nashr qilingan sanasi',
  'attribute-page_template': 'Sahifa shabloni',
  'attribute-thumbnail': 'Rasm',
  'attribute-key': 'Kalit',
  'attribute-value': 'Qiymat',
  'attribute-key.*': 'Kalit',
  'attribute-files.*': 'Media fayl',
  'attribute-is_public': 'Saytda ommaviy ravishda murojaat etiladi',
  'attribute-logotype': 'Logotip',
  'attribute-avatar': 'Avatar',
  'attribute-occupation': 'Lavozim',

  'brand_definition': 'Xizmatlarga navbatni onlayn bandlash | YouBook.Uz',
  'ok': 'OK',
  'cancel': 'Bekor qilish',
  'back': 'Ortga',
  'create': 'Yaratish',
  'edit': 'Tahrirlash',
  'update': 'Tahrirlash',
  'save': 'Saqlash',
  'delete': 'O\'chirish',
  'select': 'Tanlash',
  'resend': 'Qayta jo\'natish',
  'saved': 'Muvaffaqiyatli saqlandi',
  'current_password_incorrect': 'Joriy parol noto\'g\'ri',
  'password_changed': 'Parol o\'zgartirildi',
  'all_rights_reserved': 'Barcha huquqlar himoyalangan',
  'alternative_button_url': 'Agar siz ":action_text" tugmasini bosishda muammoga duch kelayotgan bo\'lsangiz, quyidagi URL manzilini ko\'chiring va veb-brauzeringizga joylashtiring',
  'user_avatar_removed': 'Avataringiz o\'chirib tashlandi',
  'yes': 'Ha',
  'no': 'Yo\'q',
  'for_example': 'Masalan',
  'exempli_gratia': 'masalan',
  'optional': 'majburiy emas',
  'next': 'Keyingisi',
  'for': 'uchun',
  // ==========
  'or': 'yoki',
  'toggle_navigation': 'Navigatsiyani almashtirish',
  'login': 'Kirish',
  'register': 'Ro\'yxatdan o\'tish',
  'register_phone': 'Mobil raqam orqali ro\'yxatdan o\'tish',
  'logout': 'Chiqish',
  'e_mail_address': 'Elektron pochta',
  'password': 'Parol',
  'remember_me': 'Esda saqlash',
  'forgot_your_password': 'Parolni unutdingizmi?',
  'name': 'Ism',
  'confirm_password': 'Parolni tasdiqlash',
  'reset_password': 'Parolni tiklash',
  'send_password_reset_link': 'Parolni qayta tiklash havolasini yuborish',
  'register_with_mobile_phone_number': 'Elektron pochtangiz yo\'qmi? Mobil telefon raqami orqali {get_registered}',
  'get_registered': 'ro\'yxatdan o\'ting',
  'register_with_email': 'Mobil telefon raqamingiz yo\'qmi? Elektron pochta orqali {get_registered}',
  'mobile_phone_number': 'Mobil telefon raqamingiz',
  'username': 'Foydalanuvchi nomi',
  'you_registered_successfully': 'Tizimda muvaffaqiyatli ro\'yxatdan o\'tdingiz',
  'one_id': 'Yagona Identifikatsiya Tizimi',
  'are_you_sure': 'Ishonchingiz komilmi?',
  'avatar': 'Avatar',
  'my_account': 'Mening Kabinetim',
  'phone': 'Telefon raqami',
  'current_password': 'Joriy parol',
  'password_change_wanted': 'Parolingizni o\'zgartirishni istasangiz joriy parolni va yangi parolni tasdig\'i bilan birga kiriting',
  'authentication_successful': 'Siz autentifikatsiyadan muvaffaqiyatli o\'tdingiz!',
  'media_explorer': 'Media qidiruvi',
  'upload': 'Yuklash',
  'media_list': 'Media ro\'yxati',
  'preview': 'Ko\'rib chiqish',
  'set': 'O\'rnatish',
  'close': 'Yopish',
  'payload_too_large': 'Fayllar hajmi katta!',
  'add_new': 'Qo\'shish',
  'dashboard': 'Panel',
  'personal_information': 'Shaxsiy ma\'lumotlar',
  'generic_ajax_error': 'Tizimda xatolik yuzaga keldi! Agar muammo davom etsa, iltimos, vebsayt ma\'muriga xabar bering.',
  'please_select_file': 'Iltimos yuklash uchun fotosurat faylini tanlang',
  'or_login_with_these': 'yoki quyidagilar orqali kirish',
  'pages-home': 'Bosh sahifa',
  'pages-about': 'Xizmat haqida',
  'pages-pricing': 'Tariflar',
  'pages-contact': 'Aloqa',
  'pages-faq': 'Ko\'p so\'raluvchi savollar',
  'pages-privacy': 'Maxfiylik siyosati',
  'pages-tos': 'Foydalanish shartlari',
  'pages-service-categories': 'Xizmat sohalari',
  'pages-help': 'Yordam',
  'let_us_be_social': 'Bizga qo\'shiling',
  'we_are_on_the_networks': 'Ijtimoiy tarmoqlardamiz',
  'acceptance_of_tos': 'Mazkur ilovadan foydalanish foydalanuvchining {tos}ga to\'liq roziligini bildiradi.',
  'tos_agree_text': 'xizmat ko\'rsatish shartlari',
  'no_internet_connection': 'Tarmoqda xatolik yuz berdi, qayta urinib ko\'ring',
  'you_are_not_authenticated_please_login': 'Autentifikatsiyadan o\'tmagansiz, iltimos autentifikatsiyadan o\'ting',
  'no_sufficient_rights_to_perform_action': 'Amalni bajarish uchun sizda yetarli huquq mavjud emas',
  'errorDuringSignInWithPhoneNumber': 'Xatolik, iltimos, qaytadan urinib ko\'ring',
  'errorWhileCheckingTheVerificationCode': 'Tasdiqlash kodini tekshirishda xatolik yuzaga keldi, iltimos, qaytadan urinib ko\'ring',
  'pleaseEnterTheCodeYouWillReceiveInAnSms': 'Iltimos, siz SMS orqali qabul qiladigan kodni kiriting:',
  'pleaseEnterAValidPhoneNumber': 'Iltimos, to\'g\'ri telefon raqamini kiriting!',
  'email_verified': 'Elektron pochta manzilingiz tasdiqlandi!',
  'verification_link_sent': 'Elektron pochta manzilingizga yangi tasdiqlash havolasi yuborildi.',
  'verify_your_email': 'Elektron pochta manzilingizni tasdiqlash. Davom etishdan oldin, tasdiq havolasini olish uchun elektron pochtangizni tekshiring. Agar siz xabarni olmagan bo\'lsangiz',
  'click_here_to_request_another': 'boshqasini so\'rash uchun shu yerni bosing',
  'start': 'Boshlash',

  'my_organizations': 'Mening tashkilotlarim',
  'title': 'Nomi',
  'address': 'Manzil',
  'contacts': 'Aloqa ma\'lumotlari',
  'add_new_organization': 'Yangi qo\'shish',
  'street_address': 'Massiv, ko\'cha, uy, xonadon',
  'postcode': 'Pochta indeksi',
  'email': 'Elektron pochta',
  'website': 'Vebsayt',
  'telegram': 'Telegram',
  'latitude': 'Geografik kenglik',
  'longitude': 'Geografik uzunlik',
  'location': 'Joy (viloyat, tuman, shahar)',
  'organization_title': 'Kompaniya, firma, tashkilot va h.k. nomi',
  'organization_created': 'Tashkilot muvaffaqiyatli yaratildi!',
  'organization_edited': 'Tashkilot ma\'lumotlari muvaffaqiyatli tahrirlandi!',
  'branches': 'Filiallar',
  'branch': 'Filial',
  'branch_title': 'Filial nomi',
  'add_new_branch': 'Yangi qo\'shish',
  'branch_created': 'Filial muvaffaqiyatli yaratildi!',
  'branch_edited': 'Filial ma\'lumotlari muvaffaqiyatli tahrirlandi!',
  'set_logotype': 'Logotipni o\'rnatish',
  'set_logotype_success_text': 'Logotip muvaffaqiyatli o\'rnatildi!',
  'delete_logotype_success_text': 'Logotip o\'chirib tashlandi!',
  'settings': 'Sozlamalar',
  'n_seconds': ':value sekund',
  'n_minutes': ':value daqiqa',
  'n_hours': ':value soat',
  'n_days': ':value kun',
  'n_weeks': ':value hafta',
  'n_months': ':value oy',
  'n_years': ':value yil',
  'min_time_till_event': 'Xizmatni bandlashdan oldin ruxsat etilgan minimal vaqt',
  'max_time_till_event': 'Xizmatni bandlashdan oldin ruxsat etilgan maksimal vaqt',
  'minimum_allowed_time_duration': 'Minimal ruxsat etilgan xizmat ko\'rsatish vaqti',
  'service_categories': 'Xizmat kategoriyalari',
  'organization_type': 'Tashkilot turi',
  'organizational_and_legal_form': 'Tashkiliy-huquqiy shakli',
  'service': 'Xizmat',
  'services': 'Xizmatlar',
  'add_new_service': 'Yangi qo\'shish',
  'service_provider': 'Xizmat ko\'rsatuvchi',
  'service_providers': 'Xizmat ko\'rsatuvchilar',
  'description': 'Tavsif',
  'display_on_booking_page': 'Bandlash sahifasida ko\'rsatish',
  'hide_duration_on_booking_page': 'Bandlash sahifasida umumiy vaqtni yashirish',
  'duration_hours': 'Davomiylik soati',
  'duration_minutes': 'Davomiylik daqiqasi',
  'buffer_hours_after': 'Zaxira vaqt soati',
  'buffer_minutes_after': 'Zaxira vaqt daqiqasi',
  'regular_price': 'Odatiy narx',
  'sale_percentage': 'Chegirma foizi (%)',
  'sale_price': 'Chegirmali narx',
  'service_created': 'Xizmat muvaffaqiyatli yaratildi!',
  'service_edited': 'Xizmat ma\'lumotlari muvaffaqiyatli tahrirlandi!',
  'set_thumbnail': 'Rasm o\'rnatish',
  'set_thumbnail_success_text': 'Rasm muvaffaqiyatli o\'rnatildi!',
  'delete_thumbnail_success_text': 'Rasm olib tashlandi!',
  'full_name': 'To\'liq ismi',
  'set_avatar': 'Rasm o\'rnatish',
  'set_avatar_success_text': 'Rasm muvaffaqiyatli o\'rnatildi!',
  'delete_avatar_success_text': 'Rasm olib tashlandi!',
  'add_new_service_provider': 'Yangi qo\'shish',
  'service_provider_created': 'Xizmat ko\'rsatuvchi muvaffaqiyatli yaratildi!',
  'service_provider_edited': 'Xizmat ko\'rsatuvchi ma\'lumotlari muvaffaqiyatli tahrirlandi!',
  'max_num_clients': 'Bir vaqtda xizmat ko\'rsatiluvchi mijozlarning maksimal soni',
  'set_service_categories': 'Xizmat ko\'rsatish sohalarini tanlash',
  'service_categories_select_warning': 'Diqqat! Siz xizmat ko\'rsatish sohalaringizni saqlaganingizdan keyin o\'zgartira olmaysiz, shuning uchun e\'tiborli bo\'ling.',
  'select_provider_services': 'Ushbu xodim tomonidan ko\'rsatiladigan xizmatlarni tanlang',
  'is_active_organization': 'Faoliyat yuritmoqda',
  'is_active_branch': 'Faoliyat yuritmoqda',
  'service_category': 'Xizmat kategoriyasi',
  'select_service_providers': 'Xizmat ko\'rsatuvchilarni tanlang',
  'provided_services': 'Ko\'rsatiluvchi xizmatlar',
  'service_provider_information': 'Ushbu xodim haqida ma\'lumot',
  'service_information': 'Ushbu xizmat turi haqida ma\'lumot',
  'schedule_settings': 'Ish jadvali',
  'weekly_schedule': 'Haftalik ish jadvali',
  'special_schedule': 'Maxsus kunlar ish jadvali',
  'unavailability_schedule': 'Ishlanmaydigan kunlar jadvali',
  'remove_schedule_interval': 'Oraliqni o\'chirish',
  'monday': 'Dushanba',
  'tuesday': 'Seshanba',
  'wednesday': 'Chorshanba',
  'thursday': 'Payshanba',
  'friday': 'Juma',
  'saturday': 'Shanba',
  'sunday': 'Yakshanba',
  'monday_short': 'Du',
  'tuesday_short': 'Se',
  'wednesday_short': 'Cho',
  'thursday_short': 'Pa',
  'friday_short': 'Ju',
  'saturday_short': 'Sha',
  'sunday_short': 'Ya',
  'working_time': 'Ish vaqti',
  'available_time': 'Ishlash mumkin bo\'lgan vaqt',
  'non_working_time': 'Ish vaqti emas',
  'schedule_edited': 'Ish jadvali muvaffaqiyatli tahrirlandi!',
  'minor_ordinals_masculine_1': 'Birinchi',
  'minor_ordinals_masculine_2': 'Ikkinchi',
  'minor_ordinals_masculine_3': 'Uchinchi',
  'minor_ordinals_masculine_4': 'To\'rtinchi',
  'minor_ordinals_masculine_5': 'Beshinchi',
  'minor_ordinals_masculine_last': 'Oxirgi',
  'minor_ordinals_feminine_1': 'Birinchi',
  'minor_ordinals_feminine_2': 'Ikkinchi',
  'minor_ordinals_feminine_3': 'Uchinchi',
  'minor_ordinals_feminine_4': 'To\'rtinchi',
  'minor_ordinals_feminine_5': 'Beshinchi',
  'minor_ordinals_feminine_last': 'Oxirgi',
  'minor_ordinals_neutral_1': 'Birinchi',
  'minor_ordinals_neutral_2': 'Ikkinchi',
  'minor_ordinals_neutral_3': 'Uchinchi',
  'minor_ordinals_neutral_4': 'To\'rtinchi',
  'minor_ordinals_neutral_5': 'Beshinchi',
  'minor_ordinals_neutral_last': 'Oxirgi',
  'if_exists': 'agar bo\'lsa',
  'of_month': 'oyning',
  'select_branch': 'Filialni tanlang',
  'select_date_to_set_special_schedule': 'Maxsus ish jadvalini belgilamoqchi bo\'lsangiz sanani tanlang',
  'special_schedule_for': 'Ushbu sana uchun maxsus jadval',
  'is_working_day': 'Ish kuni',
  'appointment': 'Uchrashuv/Qabul',
  'about_us': 'Biz haqimizda',
  'staff_members': 'Xodimlar',
  'booking_steps_branch': 'Filial',
  'booking_steps_service': 'Xizmat',
  'booking_steps_provider': 'Xodim',
  'booking_steps_date': 'Sana',
  'booking_steps_your_info': 'Siz haqingizda',
  'booking_steps_client': 'Mijoz',
  'booking_steps_confirm': 'Tasdiqlash',
  'booking_steps_client_info': 'Mijoz haqida',
  'booking_steps_place': 'Joy',
  'booking_steps_inventory': 'Inventar',
  'organization_description': 'Tashkilot tavsifi, ushbu tashkilot haqida ma\'lumot',
  'branch_description': 'Filial tavsifi, ushbu filial haqida ma\'lumot',
  'until_you_select_special_time_and_press_save_button': 'Maxsus kunni tanlab va shu kun uchun maxsus ish jadvalini belgilab, "Saqlash" tugmasini bosmaguningizgacha hech qanday o\'zgarish saqlanmaydi. Maxsus sana jadvali boshqa kunlarga qaraganda ustunlikka ega.',
  'select_service': 'Xizmatni tanlang',
  'you_can_select_multiple_services': 'Bir nechta xizmat turini tanlashingiz mumkin',
  'shortened_time_labels_h': 's.',
  'shortened_time_labels_m': 'd.',
  'shortened_time_labels_s': 's.',
  'cost': 'Narxi',
  'duration': 'Davomiylik',
  'free_of_charge': 'Bepul',
  'unknown': 'Noma\'lum',
  'select_service_provider': 'Xodimni tanlang',
  'select_datetime': 'Kun va vaqtni tanlang',
  'first_name': 'Ism',
  'last_name': 'Familiya',
  'middle_name': 'Otasining ismi',
  'mandatory_fields': 'To\'ldirilishi shart bo\'lgan maydonlar',
  'client': 'Mijoz',
  'confirm_booking_details': 'Ma\'lumotlarni tasdiqlash',
  'date': 'Sana',
  'starts_at': 'Boshlanish vaqti',
  'confirm_booking': 'Tasdiqlash',
  'add_another_service': 'Yana xizmat qo\'shish',
  'branch_schedule_may_differ_from_organization': 'Filial ish jadvali bosh ofis ish jadvalidan farq qilishi mumkin',
  'provider_services': 'Ushbu xodim tomonidan ko\'rsatiladigan xizmatlar',
  'higher_priority_than_4_th_or_5_th_day': 'Oyning 4- yoki 5-hafta kunidan ustuvorroq',
  'select_another_date_and_time': 'Siz tanlagan sana va vaqt allaqachon tanlangan xizmatlar vaqti bilan kesishadi. Iltimos, boshqa sana va vaqtni tanlang.',
  'select_other_available_date_and_time': 'Siz tanlagan sana va vaqt ishlanmaydigan vaqt bilan kesishadi. Iltimos, boshqa sana va vaqtni tanlang.',
  'unavailable_date_and_time': 'Siz ko\'rsatgan sana va vaqtni tanlab bo\'lmaydi. Iltimos, boshqa sana va vaqtni tanlang.',
  'select_other_date_and_time_slots': 'Siz tanlagan sana va vaqt ishlanmaydigan vaqt bilan kesishadi. Iltimos, boshqa sana va vaqtni tanlang.',
  'special_schedule_applies_to_service_providers': 'Maxsus jadval ushbu filialning xodimlari uchun ham amal qiladi.',
  'working_time_in_other_branches': 'Boshqa filiallardagi ish vaqti',
  'authorize_suggest_prompt_text': 'Har safar maydonlarni to\'ldirmaslik uchun ro\'yxatdan o\'ting yoki shaxsiy kabinetingizga kiring',
  'service_provider_not_available': 'Tanlangan vaqt uchun xizmat ko\'rsatuvchi band qiligan, kimdir allaqachon buyurtma berganga o\'xshaydi. Iltimos, boshqa vaqtni yoki xizmat ko\'rsatuvchi xodimni tanlang',
  'select_at_least_one_service_to_book': 'Iltimos, buyurtma uchun kamida bitta xizmatni tanlang',
  'occupied': 'band',
  'working_time_slot': 'ish vaqti',
  'partly_available_service_provider_time_slot': 'qisman band bo\'lgan vaqt (xizmat ko\'rsatuvchi o\'sha xizmat turi bo\'yicha qabul qila oladi)',
  'non_working_or_booked_time_slot': 'ishlanmaydigan yoki band qilingan vaqt oralig\'i',
  'select_other_slot_or_the_beginning_of_booked_time_slot': 'Siz tanlagan sana va vaqt band qilingan vaqt bilan kesishadi. Iltimos, boshqa sana va vaqtni yoki o\'sha xizmat turi uchun boshlanish vaqtini tanlang.',
  'select_other_slot': 'Siz tanlagan sana va vaqt band qilingan vaqt bilan kesishadi. Iltimos, boshqa sana va vaqtni tanlang.',
  'my_bookings_and_appointments': 'Qabullar va uchrashuvlar',
  'my_restaurant_bookings': 'Joy buyurtmasi',
  'my_rentals': 'Ijara xizmati',
  'my_events_and_entertainment': 'Tadbirlar va ko\'ngilochar dasturlar',
  'booking_accepted': 'Qabul qilindi!',
  'your_booking_accepted_text': 'Sizning so\'rovingiz qabul qilindi. Agar sizda savollar tug\'ilgan bo\'lsa yoki fikr-mulohazalaringiz bo\'lsa, aloqa ma\'lumotlari orqali tashkilot ma\'muriyati bilan bog\'lanishingiz mumkin. Tashakkur!',
  'to_continue_press_close_button': 'Davom etish uchun yopish tugmasini bosing.',
  'select_your_organization': 'Tashkilotingizni tanlang',
  'code': 'Kod',
  'booking_code': 'Bandlash kodi',
  'created_at': 'Yaratilgan sana',
  'status': 'Holat',
  'actions': 'Harakatlar',
  'active': 'Faol',
  'inactive': 'Nofaol',
  'new': 'Yangi',
  'pending': 'Kutilmoqda',
  'approved': 'Tasdiqlangan',
  'cancelled': 'Bekor qilingan',
  'expired': 'Muddati tugagan',
  'view': 'ko\'rish',
  'show_filters': 'Filtrlarni ko\'rsatish',
  'hide_filters': 'Filtrlarni yashirish',
  'apply': 'Tadbiq etish',
  'clear': 'Tozalash',
  'created_at_from': 'Yaratilgan sana (dan)',
  'created_at_to': 'Yaratilgan sana (gacha)',
  'service_at_from': 'Xizmat vaqti (dan)',
  'service_at_to': 'Xizmat vaqti (gacha)',
  'please_wait': 'Kuting',
  'bookings_and_appointments': 'Qabullar va uchrashuvlar',
  'select_please': 'Iltimos, tanlang',
  'booking_types_select_appointments': 'Qabullar va uchrashuvlar',
  'booking_types_select_services': 'Qabullar va uchrashuvlar',
  'booking_types_select_rentals': 'Ijara xizmati',
  'booking_types_select_restaurants': 'Joy buyurtmasi',
  'booking_types_select_reservations': 'Joy buyurtmasi',
  'booking_types_select_events': 'Tadbirlar uchun buyurtma',
  'booking_channels_for_clients': 'Mijozlar uchun bandlash yoki qabulga yozilish usullari',
  'booking_channels_by_phone': 'Telefon orqali',
  'booking_channels_on_shopfront': 'Tashkilot veb-sahifasida',
  'bookings_performed_hints_by_phone': 'Administrator tomonidan telefon orqali qabul qilinadi, bu usul har doim mavjud',
  'bookings_performed_hints_on_shopfront': 'Tashkilotning veb-sahifasida mijozlar tomonidan mustaqil ravishda amalga oshiriladi',
  'notification_channels_for_clients': 'Mijozlar uchun bildirishnomalar yuborish usullari',
  'notification_channels_by_email': 'Elektron pochta orqali',
  'notification_channels_by_telegram': 'Telegram orqali',
  'notification_channels_by_sms': 'SMS orqali',
  'notifications_performed_hints_by_email': 'Xabar mijozning elekton pochta manziliga (agar mavjud bo\'lsa) yuboriladi, bu usul doim yoqilgan',
  'notifications_performed_hints_by_telegram': 'Xabar mijozning telegram akkauntiga (agar mavjud bo\'lsa) yuborilad, bu usul doim yoqilgan',
  'notifications_performed_hints_by_sms': 'Xabar mijozning mobil telefon raqamiga yuboriladi, bu usulni yoqib-o\'chirish mumkin',
  'make_a_call': 'qo\'ng\'iroq qilish',
  'not_accepted_on_shopfront_page': 'Ushbu tashkilot veb-sahifasida buyurtma, qabulga yozilishlar va ijara so\'rovlarini qabul qilmaydi. O\'zingiz uchun qulayroq bo\'lgan usullardan foydalangan holda ma\'muriyat bilan bog\'lanishingiz mumkin. Tashakkur!',
  'you_can_use_the_following_contact_details': 'Siz quyidagi aloqa ma\'lumotlaridan foydalanishingiz mumkin',
  'my_administrators': 'Administratorlar',
  'my_administrator_deleted': 'Administrator o\'chirildi',
  'administrator_created_successfully': 'Administrator muvaffaqiyatli yaratildi',
  'error_while_creating_administrator': 'Administrator yaratishda xatolik yuz berdi',
  'organizations_or_branches': 'Tashkilotlar (filiallar)',
  'set_administrator_branches': 'Ushbu operator qaysi tashkilot (filial)laringizga xizmat ko\'rsatishini belgilang',
  'search_organization': 'Tashkilotni izlash',
  'go_to_organization_page': 'O\'tish',
  'nothing_under_this_service_category': '\':service_category\' xizmat kategoriyasida tashkilotlar topilmadi',
  'try_searching_in_other_categories': 'Iltimos, <a href=\':categories_url\'>boshqa kategoriyalardan</a> izlab ko\'ring',
  'doesnt_supply_services_yet': 'Ushbu tashkilot hozircha xizmat ko\'rsatmaydi',
  'try_searching_for_other_organizations': 'Boshqa tashkilotlarni izlab ko\'ring',
  'for_other_organizations': 'Boshqa tashkilotlarni',
  'no_organization_found_in_search': 'Ushbu \':search_token\' so\'rovingiz bo\'yicha hech narsa topilmadi',
  'found_organizations_for_your_search': 'Ushbu \':search_token\' so\'rovingiz bo\'yicha topilgan natijalar',
  'organization_search_hint': 'Tashkilot nomi, joylashgan shahar yoki tuman nomi, manzili',
  'whereabouts': 'Joylashgan joyi',
  'search': 'izlash',
  'no_organizations_found': 'Tashkilotlar topilmadi',
  'minute': 'd.',
  'calendar': 'Kalendar',
  'list': 'Ro\'yxat',
  'details': 'Ma\'lumotlar',
  'passphrase': 'Kalit so\'z, parol',
  'approve_booking': 'Tasdiqlash',
  'cancel_booking': 'Bekor qilish',

  'rentals': 'Ijara',
  'rental_services': 'Ijara xizmatlari',
  'inventory': 'Inventar',
  'table_place_booking': 'Stol, joy buyurtmasi',
  'tables_rooms': 'Stollar, xonalar, joylar',
  'events_and_entertainment': 'Tadbirlar, ko\'ngilochar dasturlar',
  'tickets_seats_rooms': 'Chiptalar, o\'rindiqlar, xonalar',
  'serves_which_branches': 'Xizmat ko\'rsatuvchi filiallari',
  'occupation': 'Lavozim',
  'make_an_appointment': 'Uchrashuv (qabul) belgilash',
  'rent': 'Ijaraga berish',
  'make_a_reservation': 'Joy buyurtma qilish',
  'sign_up_for_event': 'Tadbirga ro\'yxatdan o\'tkazish',
  'reservables': 'Stollar, joylar, xonalar',
  'short_information': 'Qisqacha ma\'lumot',
  'any_short_information': 'Ixtiyoriy qisqacha ma\'lumot',
  'reservable_type_table': 'Stol',
  'reservable_type_room': 'Xona/joy',
  'title_or_number': 'Nomi yoki raqami (№)',
  'select_what_to_create': 'Nimani yaratishni tanlang',
  'please_select_an_image_file': 'Iltimos rasm tanlang!',
  'room_type': 'Xona turi',
  'room_type_room': 'Xona',
  'room_type_hall': 'Zal',
  'room_type_balcony': 'Balkon',
  'rentables': 'Ijaraga beriladiganlar',
  'sku': 'Artikul (SKU)',
  'inventory_availability_determined_by_sku': 'Bir xil nomli inventarlarning mavjudligi artikul (SKU) bilan aniqlanadi',
  'service_type_service': 'Xizmat',
  'service_type_appointment': 'Uchrashuv/Qabul',
  'service_type_training': 'Mashg\'ulot',
  'service_type_tutoring': 'Repetitorlik',
  'service_type_other_service': 'Boshqa xizmat turi',
  'type_of_tariffication': 'Tarifikatsiya turi',
  'tariffication_type_hourly': 'Soatlarda',
  'tariffication_type_daily': 'Kunlarda',
  'tariffication_type_weekly': 'Haftalarda',
  'tariffication_type_monthly': 'Oylarda',
  'rentable_type_book': 'Kitob',
  'rentable_type_real_estate': 'Ko\'chmas mulk',
  'rentable_type_room': 'Nomer/Xona',
  'rentable_type_heavy_machinery': 'Og\'ir texnika',
  'rentable_type_construction_equipment': 'Qurilish anjomi',
  'rentable_type_car': 'Mashina',
  'rentable_type_bicycle': 'Velosiped',
  'rentable_type_sport_equipment': 'Sport inventari',
  'rentable_type_disk': 'Disk',
  'rentable_type_other_inventory': 'Boshqa inventar',
  'events': 'Tadbirlar',
  'event_name': 'Tadbir yoki voqea nomi',
  'event_information': 'Ushbu tadbir haqida ma\'lumot',
  'stock_count': 'Miqdor',
  'event_datetime_from': 'Tadbir sanasi va vaqti (dan)',
  'event_datetime_to': 'Tadbir sanasi va vaqti (gacha)',
  'select_branches_where_this_service_provided': 'Ushbu xizmat ko\'rsatiladigan filial(lar)ni tanlang',
  'select_branch_where_this_inventory_belongs_to': 'Ushbu inventar tegishli bo\'lgan filialni tanlang',
  'venue_arrangement': 'O\'tkazish joylarini tayyorlash',
  'venues': 'O\'tkazish joylari',
  'create_one_by_one': 'Birma-bir yaratish',
  'generate_in_bulk': 'Bir yo\'la bir nechta yaratish',
  'generate': 'Yaratish',
  'venue_type_ticket': 'Chipta',
  'venue_type_seat': 'O\'rindiq',
  'venue_type_room': 'Xona',
  'venue_number_or_title': 'Raqam (№) yoki nomi',
  'row_number': 'Row number',
  'seat_number': 'Seat number',
  'row': 'Row',
  'seat': 'Seat',
  'page_not_found': 'Siz izlayotgan sahifa topilmadi',
  'places': 'Joylar',
  'subtype': 'Predmet',
  'schedule': 'Ish jadvali',
  'lasting': 'Davomiylik',
  'category': 'Kategoriya',
  'client_info': 'Mijoz ma\'lumotlari',
  'select_place': 'Joyni tanlang',
  'indicate_how_long_will_stay': 'Iltimos, qancha vaqt qolishingizni belgilang (davomiyligi daqiqalarda)',
  'hours': 's.',
  'max_stay_duration_is_until_workday_end': 'maksimal qolish muddati faqat ish kunining oxirigacha bo\'lishi mumkin',
  'place': 'Joy',
  'time': 'Vaqti',
  'select_inventory': 'Inventarni tanlang',
  'you_can_rent_only_on_one_tariffication_basis': 'Bir vaqtning o\'zida siz faqat bitta tarif turi asosida ijaraga olishingiz mumkin',
  'indicate_how_long_will_rent': 'Iltimos, qancha vaqtgacha ijaraga olishingizni belgilang',
  'renting_duration_hourly': 'davomiyligi soatlarda',
  'renting_duration_daily': 'davomiyligi kunlarda',
  'renting_duration_weekly': 'davomiyligi haftalarda',
  'renting_duration_monthly': 'davomiyligi oylarda',
  'renting_period_from': 'Ijara muddati, dan',
  'renting_period_to': 'Ijara muddati, gacha',
  'bookings': 'Bandlashlar',
  'view_type': 'Ko\'rinish',
  'datetime_from': 'Vaqt, dan',
  'datetime_to': 'Vaqt, gacha',
  'service_s': 'Xizmat(lar)',
  'bookables_total': 'Jami',
  'text_copied': 'Matn nusxalandi!',
  'cost_depends_on_order': 'Narx buyurtmaga bog\'liq',
  'schedule_was_not_set': 'Ish jadvali belgilanmagan',
  'service_datetime_from': 'Xizmat vaqti (dan)',
  'service_datetime_to': 'Xizmat vaqti (gacha)',
  'rental_return_time_is_non_working': 'Inventarni qaytarish vaqti ishlanmaydigan vaqt, iltimos, boshqa vaqtni tanlang yoki tashkilot xodimlariga murojaat qiling.',
  'telegram_username': 'Telegramdagi foydalanuvchi nomi (@ belgisisiz)',
  'telegram_username_hint': '<a target="_blank" href="' + process.env.REACT_APP_TELEGRAM_BOT_URL + '">bot</a>imizga ruxsat berilsa, ushbu akkauntga bizdan bildirishnomalar olish mumkin, buning uchun botda <a target="_blank" href="' + process.env.REACT_APP_TELEGRAM_BOT_DESCRIPTION_URL + '">/link</a> buyrug\'ini terish yetarli',
  'booking_information': 'Bandlash tafsilotlari',
  'client_notes': 'Mijoz izohlari',
  'admin_notes': 'Operator izohlari',
  'cancellation_reason': 'Bekor qilish sababi',
  'notes': 'Izohlar',
  'dont_forget_to_leave_notes': 'Agar mavjud bo\'lsa, iltimos, "Izohlar" bo\'limida bekor qilish sababini ko\'rsating.',
  'inputs_are_saved_automatically': 'kiritilgan matn avtomatik tarzda saqlanadi',
  'client_preferred_language': 'Muloqot uchun ma\'qul ko\'riladigan til',
  'notifications': 'Bildirishnomalar',
  'message': 'Xabar',
  'read_at': 'O\'qilgan vaqt',
  'you_dont_have_notifications_yet': 'Sizda hozircha xabarlar mavjud emas',
  'got_impressed_to_our_features': 'Ilovamiz imkoniyatlari sizga ma\'qul keldimi?',
  'got_impressed_get_register': 'Unda vaqtingizni behuda sarflamang va biz bilan band qilish biznesingizni hoziroq boshlang',
  'get_to_know': 'Tanishish',
  'get_started_now': 'Hoziroq boshlang',
  'how_it_works': 'Bu qanday ishlaydi',
  'all_service_categories': 'Barchasini ko\'rish',
  'our_service_categories': 'Xizmat ko\'rsatish sohalarimiz',
  'choose_service_category_fitting_your_business': 'Biznesingizga mos bo\'lgan xizmat ko\'rsatish toifasini tanlang',
  'forbidden': 'Taqiqlangan',
  'go_back_home': 'Bosh sahifaga o\'tish',
  'make_a_booking': 'Band qilish',
  'faq': 'Ko\'p so\'raluvchi savollar',
  'notifications_will_be_sent': 'bildirishnomalar jo\'natiladi',
  'timeslots_shifting_and_time_gaps_in_between_warning': 'Vaqt oralig\'ini iloji boricha eng so\'nggi bandlashga yoki eng yaqin ish soatiga yaqin qilib tanlashga harakat qiling. Band qilish imkonsiz bo\'lgan vaqt oralig\'i hosil bo\'lishidan qochishga urinmaslik, tashkilot ma\'muriyatining buyurtmangizni bekor qilishiga olib kelishi mumkin.',
  'help_center': 'Yordam markazi',
  'enter_some_keywords': 'Kalit so\'zlarni kiriting',
  'nothing_found': 'Hech narsa topilmadi',
  'view_booking_details': 'Batafsil ma\'lumotlarni quyidagi havola orqali ko\'rishingiz mumkin',
  'can_you_return_inventory_until_the_end_of_the_working_hours': 'Inventarni qaytarishning aniq vaqti, afsuski, ishlanmaydigan vaqtga to\'g\'ri kelmoqda. Ish vaqti tugagunga qadar inventarni qaytarib bera olasizmi? Aytaylik, 5 daqiqa oldin?',
  'attention': 'Diqqat!',
  'place_type': 'Joy turi',
  'max_time_till_event_text': 'Xizmatni bandlashdan oldin ruxsat etilgan maksimal vaqt -',
  'too_many_requests': 'Siz juda ko\'p so\'rov yubordingiz, iltimos, birozdan keyin qayta urinib ko\'ring!',
  'balance': 'Balans',
  'did_not_find_what_you_were_looking_for': 'Izlagan narsangizni topmadingizmi?',
  'there_is_an_update': 'Ilovaning yangi versiyasi mavjud!',
  'refresh-now': 'Yangilash',
  'not-now': 'Hozir emas',
  'we_use_cookies_notification': 'Biz ish qobiliyatini ta\'minlash va xizmat sifatini yaxshilash uchun cookie fayllaridan foydalanamiz.',
  'i_agree': 'Roziman',
  'menu': 'Menu',
  'paid_sms_service_warning': 'sms xizmati pullik, har bir sms uchun yagona narx ' + process.env.REACT_APP_SMS_COST + ' so\'m, mijozlarga sms xabarlar bandlash amalga oshirilganda, tasdiqlanganda, bekor qilinganda hamda tashkilot, filial va xizmat ko\'rsatuvchi xodimlar ish jadvali o\'zgartirilganda jo\'natiladi',
  'go_to_booking_page': 'bandlash sahifasiga o\'tish',
  'reset_password_with_email': 'El. pochta yordamida parolni tiklash',
  'reset_password_with_otp': 'Kod yordamida parolni tiklash',
  'confirm_mobile_number': 'Mobil raqamni tasdiqlash',
  'email_must_be_verified_to_get_notifications': 'Bildirishnomalarni, jumladan, unutib qo\'ysangiz, parolni tiklash haqida bildirishnoma olishingiz uchun elektron pochta manzilingiz tasdiqlangan bo\'lishi zarur.',
  'add_email_or_telegram_account_warning': 'Siz elektron pochta manzilini va telegram akkauntini qo\'shmagansiz. Iltimos, bizdan bildirishnomalar olish va parolni unutib qo\'ysangiz, uni qayta tiklash imkoniyatiga ega bo\'lishingiz uchun ikkalasini yoki birortasini qo\'shing.',
  'telegram_not_linked_warning': 'ushbu telegram akkaunt tizimga ulanmagan, ulash uchun <a target="_blank" href="' + process.env.REACT_APP_TELEGRAM_BOT_URL + '">bot</a>imizga kirish va <span class="text-info">/link</span> buyrug\'ini terish lozim',
  'otp': 'Tasdiqlash kodi',
  'send_otp': 'Tasdiqlash kodini jo\'natish',
  'enter_telegram_otp': 'Telegram akkauntingizga keladigan kodni kiriting',
  'invoices': 'Hisob-fakturalar',
  'invoices_do_not_exist': 'Hisob-fakturalar hali mavjud emas',
  'you_have_due_invoices': 'To\'lanishi lozim bo\'lgan hisob-fakturalar mavjud',
  'download': 'yuklab olish',
  'paid': 'to\'langan',
  'unpaid': 'to\'lanmagan',
  'no_invoices_yet': 'Hozircha hisob-fakturalar mavjud emas',
  'invoices_created_automatically_hint': 'Hisob-fakturalar avtomatik ravishda yaratiladi va har bir keyingi kalendar oyning boshida to\'lanishi lozim',
  'contact_us_in_convenient_way': 'O\'zingiz uchun qulayroq bo\'lgan usulda biz bilan bog\'lanishingiz mumkin:',
  'contact_via_telegram': 'Istalgan vaqtda Telegram orqali xabaringizni yuboring!',
  'contact_via_email': 'Shuningdek, elektron pochtamizga murojaat yo\'llashingiz mumkin!',
  'contact_via_phone': 'Hamkorlik yuzasidan qo\'ng\'iroq qiling!',
  'looking_for_a_guide': 'Ilova bilan ishlash bo\'yicha qo\'llanmani izlayapsizmi?',
  'install-now': 'O\'rnatish',
  'understand': 'Tushunarli',
  'get_to_know_in_detail': 'Batafsil bilib olish',
  'this_pwa_can_be_installed': 'Ish stoliga yorliqni qo\'yish orqali ushbu veb-ilovani qurilmangizga o\'rnatib oling, bu esa keyinchalik unga bir marta bosish orqali kirish imkonini beradi',
  'unhandled_error_hint': 'Nimadir noto\'g\'ri bajarildi! Sahifani qayta yuklashga urinib ko\'ring, agar muammo davom etsa, ma\'muriyatga xabar bering. Sahifani suratga olib (screenshot) jo\'natishingiz ham mumkin',
  'reload': 'Qayta yuklash',
  'organization_telegram_username_hint': 'foydalanuvchi, guruh yoki kanal nomi (username)',
  'enter_sms_otp': 'SMS orqali keladigan kodni kiriting',
  'get_to_know_yet_more': 'Batafsil bilib olish',
  'user_privacy_warning_for_representative': '{privacy_policy}ga muvofiq ushbu {user_type}dan shaxsga doir ma\'lumotlarga ishlov berilishiga ruxsat olingan bo\'lishi zarur. Buning uchun tashkilot vakili javobgardir.',
  'user_type_administrator': 'administrator',
  'user_type_service_provider': 'xizmat ko\'rsatuvchi xodim',
  'delete_account_text': 'Shaxsiy akkauntni o\'chirish',
  'delete_account_hint': 'Akkauntingizni o\'chirish unga tegishli barcha ma\'lumotlar, jumladan, tashkilotlaringiz va barcha tegishli ma\'lumotlar, avval amalga oshirilgan bandlashlar va hokazolarni qayta tiklash imkoniyatisiz o\'chirib tashlanishiga olib keladi.',
  'delete_my_account_text': 'Akkauntimni o\'chirish',
  'enter_password_to_confirm_identity': 'Shaxsingizni tasdiqlash uchun parolingizni kiriting',
  'yes_absolutely': 'Ha, aniq',
  'changed_my_mind': 'Yo\'q, fikrimdan qaytdim',
  'all_account_info_shall_be_deleted': 'Ishonchingiz komilmi? Akkauntingizga tegishli barcha ma\'lumotlar o\'chiriladi va keyin uni qayta tiklay olmaysiz!',
  'contact_via_phone_work_hours': 'Du. - Ju., 09:00 - 18:00',
  'who_made_the_booking': 'Kim bajargan',
  'the_client_themselves': 'Mijoz o\'zi mustaqil',
  'is_deleted': 'o\'chirilgan',
  'service_type': 'Xizmat turi',
  'information': 'Ma\'lumot',
  'price': 'Narx',
  'decides_whether_to_show_to_clients': 'Mijozlarga ko\'rsatish yoki ko\'rsatmaslikni hal qiladi',
  'mark_as_read': 'O\'qildi',
  'client_acceptance_of_tos': 'Mijoz shaxsga doir ma\'lumotlarini taqdim etish orqali {tos}ga to\'liq rozilik bildiradi.',
  'invoice_total_abs': 'Jami',
  'invoice_total_bookings': 'Jami bandlashlar',
  'invoice_total_smses': 'Jami SMS',
  'select_served_branches': 'Ushbu xodim tomonidan xizmat ko\'rsatiluvchi filiallarni tanlang',
  'etc': 'Va boshqalar',
  'get_current_coordinates_short_text': 'koordinatalarni olish',
  'get_current_coordinates': 'joriy joylashuvga asosan koordinatalarni olish',
  'welcome': 'Xush kelibsiz',
  'i_am_a_service_provider': 'Men xizmat ko\'rsatuvchiman',
  'i_am_a_customer': 'Men mijozman',
  'get_help': 'Yordam',
  'not_applicable': 'Mavjud emas',
  'enable_push_notifications': 'Push-xabarnomalarni yoqish',
  'notifications_settings': 'Xabarnomalar',
  'browser_does_not_support_notifications': 'Ushbu brauzer xabarnomalarni qo\'llab-quvvatlamaydi.',
};

export default uz;
