export function submitLogin(data) {
  return (dispatch, getState) => {
    data.locale = getState().lang;
    return window.axios
      .post('/auth/login', data)
      .then((result) => {
        if (result && result.status === 200) {
          window.localStorage.setItem('user-token', result.data.access_token);
          window.localStorage.setItem('user-token-expiry', window.moment().add(process.env.REACT_APP_USER_TOKEN_EXPIRY, 'm'));
          dispatch({
            type: 'LOGGED_IN',
            payload: result.data.authUser,
          });
        }
      });
  };
}

export function getAuthenticatedUser() {
  return (dispatch, getState) => {
    return window.axios
      .post('/auth/me')
      .then((result) => {
        if (result && result.status === 200) {
          dispatch({
            type: 'SET_AUTH_USER',
            payload: result.data,
          });
        }
      })
      .catch((error) => {
        const cachedAuthUser = window.localStorage.getItem('auth-user');
        if (error.message === 'Network Error' && cachedAuthUser) {
          dispatch({
            type: 'SET_AUTH_USER',
            payload: JSON.parse(cachedAuthUser),
          });
        }
      });
  };
}

export function logout(afterUnauthenticatedAction) {
  if (! afterUnauthenticatedAction) {
    const userToken = window.localStorage.getItem('user-token');
    window.axios.post('/auth/logout', {}, {
      headers: {
        'Authorization': 'Bearer ' + userToken,
      },
    });
  }
  window.localStorage.removeItem('user-token');
  window.localStorage.removeItem('user-token-expiry');
  window.localStorage.removeItem('auth-user');
  window.jQuery('.modal.show').modal('hide');
  return {
    type: 'LOGGED_OUT',
    payload: {},
  };
}
