import { Fragment, useEffect, Suspense, lazy, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, useHistory, Prompt } from 'react-router-dom';
import messages from './lang/messages';
import locales from './lang/locales';
import { setLang } from './actions/index';
import { getAuthenticatedUser } from './actions/auth/login';
import { helperScrollUp, helperToggleFooterSticky, helperDefaultLang, helperRetryImport } from './helpers/index';
import SuspenseFallback from './components/SuspenseFallback';
import * as ServiceWorkerRegistration from './ServiceWorkerRegistration';
const Header = lazy(() => helperRetryImport(() => import('./components/Header')));
const Home = lazy(() => helperRetryImport(() => import('./containers/front/Home')));
const ErrorsAlert = lazy(() => helperRetryImport(() => import('./components/ErrorsAlert')));
const VerifyEmailAlert = lazy(() => helperRetryImport(() => import('./components/VerifyEmailAlert')));
const Footer = lazy(() => helperRetryImport(() => import('./components/Footer')));
const ImageMagnifierPopup = lazy(() => helperRetryImport(() => import('./components/ImageMagnifierPopup')));
const PageNotFound = lazy(() => helperRetryImport(() => import('./containers/front/PageNotFound')));
const Forbidden = lazy(() => helperRetryImport(() => import('./containers/front/Forbidden')));
const MyAccount = lazy(() => helperRetryImport(() => import('./containers/myaccount/Index')));
const Login = lazy(() => helperRetryImport(() => import('./containers/auth/Login')));
const Register = lazy(() => helperRetryImport(() => import('./containers/auth/Register')));
const RegisterPhone = lazy(() => helperRetryImport(() => import('./containers/auth/RegisterPhone')));
const ForgotPassword = lazy(() => helperRetryImport(() => import('./containers/auth/ForgotPassword')));
const ResetPassword = lazy(() => helperRetryImport(() => import('./containers/auth/ResetPassword')));
const ResetPasswordOtp = lazy(() => helperRetryImport(() => import('./containers/auth/ResetPasswordOtp')));
const VerifyEmail = lazy(() => helperRetryImport(() => import('./containers/auth/VerifyEmail')));
const TermsOfService = lazy(() => helperRetryImport(() => import('./containers/front/TermsOfService')));
const PrivacyPolicy = lazy(() => helperRetryImport(() => import('./containers/front/PrivacyPolicy')));
const FAQ = lazy(() => helperRetryImport(() => import('./containers/front/FAQ')));
const About = lazy(() => helperRetryImport(() => import('./containers/front/About')));
const Pricing = lazy(() => helperRetryImport(() => import('./containers/front/Pricing')));
const Contact = lazy(() => helperRetryImport(() => import('./containers/front/Contact')));
const HelpCenter = lazy(() => helperRetryImport(() => import('./containers/front/HelpCenter')));
const OrganizationsList = lazy(() => helperRetryImport(() => import('./containers/front/OrganizationsList')));
const Shopfront = lazy(() => helperRetryImport(() => import('./containers/front/Shopfront')));
const ServiceCategories = lazy(() => helperRetryImport(() => import('./containers/front/ServiceCategories')));
const Modal = lazy(() => helperRetryImport(() => import('./components/Modal')));
const CookieNotification = lazy(() => helperRetryImport(() => import('./components/CookieNotification')));
const InstallPWAPrompt = lazy(() => helperRetryImport(() => import('./components/InstallPWAPrompt')));

function AppSwitch(props) {
  const [locale, setLocale] = useState(null);
  const history = useHistory();

  useEffect(() => {
    history.listen((nextLocation) => {
      const navbarCollapse = window.jQuery('.navbar-collapse');
      // ensure 'collapse' function has been loaded and available
      if (navbarCollapse && typeof window.jQuery.fn.collapse === 'function') {
        navbarCollapse.collapse('hide');
      }
      if (nextLocation.pathname.indexOf('/login') === -1) {
        // console.log('next redirect: ' + nextLocation.pathname);
        window.localStorage.setItem('prev-location', JSON.stringify(nextLocation));
      }
      // manually send page view events to analytics systems
      const pageUrl = nextLocation.pathname + nextLocation.search;
      // console.debug(`page_view: ${ pageUrl }`);
      sendPageViewToGoogleAnalytics(pageUrl);
      sendPageViewToYandexMetrika(pageUrl);
    });
  }, [history]);

  function sendPageViewToGoogleAnalytics(pageUrl) {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_location: pageUrl,
      });
    } else {
      // console.error('gtag not set!');
    }
  }

  function sendPageViewToYandexMetrika(pageUrl) {
    if (window.ym) {
      window.ym(process.env.REACT_APP_YANDEX_METRIKA_TRACKING_ID, 'hit', pageUrl);
    } else {
      // console.error('ym not set!');
    }
  }

  useEffect(() => {
    if (props.loggedIn) {
      props.getAuthenticatedUser();
    }

    window.addEventListener('resize', helperToggleFooterSticky);
    window.addEventListener('scroll', helperToggleFooterSticky);

    return () => {
      window.removeEventListener('resize', helperToggleFooterSticky);
      window.removeEventListener('scroll', helperToggleFooterSticky);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.loggedIn) {
      return checkForUnreadNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loggedIn]);

  function checkForUnreadNotifications() {
    // periodically (every 5 minutes) check for unread notifications
    const intervalId = setInterval(function () {
      console.log('Checking for unread notifications...');
      props.getAuthenticatedUser();
    }, 300000);

    return () => {
      clearInterval(intervalId);
    };
  }
  
  useEffect(() => {
    helperScrollUp();
    helperToggleFooterSticky();
  });

  const lang = props.match.params.lang;
  const existingLang = Object.keys(locales).includes(lang);
  useEffect(() => {
    if (existingLang) {
      props.setLang(lang);
      const metaDescription = messages[lang] ? messages[lang].brand_definition : '';
      window.document.querySelector('html').setAttribute("lang", lang);
      window.document.querySelector('meta[name="description"]').setAttribute("content", metaDescription);
      window.document.querySelector('meta[property="og:url"]').setAttribute("content", window.location.origin + '/' + lang);
      window.document.querySelector('meta[property="og:description"]').setAttribute("content", metaDescription);
      const pageTitle = (props.pageTitle ? (props.pageTitle + ' - ') : '') + metaDescription;
      window.document.title = pageTitle;
      window.document.querySelector('meta[property="og:title"]').setAttribute("content", pageTitle);
      window.document.querySelector('#noscript-warning').textContent = messages[lang].noscript_warning;

      window.moment.locale(lang === 'uz' ? 'uz-latn' : lang);

      setLocale(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, existingLang, props.pageTitle]);

  const defaultLang = helperDefaultLang();

  if (! existingLang) {
    return <Redirect to={ props.location.pathname.replace(lang, defaultLang) } />
  }

  const isFrontPage = (props.location.pathname === ('/' + lang) || props.location.pathname === '/' || props.location.pathname === '') && ! props.isPwaInstalled;
  const isAccountPages = (props.location.pathname.indexOf('/' + lang + '/my-account') > -1);

  return (
    // specify key to force-render the whole component tree on locale change
    <Fragment key={ `app-key:${locale}` }>
      <Prompt
        when={ true }
        message={ (location) => {
          // prevent bootstrap modal staying open and having redundant modal-backdrop on react route change
          const modals = window.jQuery('.modal.show');
          if (modals.length) {
            modals.modal('hide');
          }
          return window.jQuery('body').hasClass('modal-open') ? messages[locale]['are_you_sure'] : true;
        } }
      />
      <Suspense fallback={ <SuspenseFallback /> }>
        <Header locationPathname={ props.location.pathname } />
      </Suspense>
      <main className="flex-grow-1">
        {
          (isFrontPage) ?
            null
            :
            <>
              <section className="banner_area">
                <div className="banner_inner d-flex align-items-center">
                  <div className="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
                </div>
              </section>
              {
                (props.shouldDisplayPageTitle) ?
                  <section className="made_life_area p_120">
                    <div className="container">
                      <div className="made_life_inner">
                        <div className="row made_life_text text-center">
                          <div className="col-12">
                            <h1>{ props.pageTitle }</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  :
                  null
              }
            </>
        }
        {
          (isAccountPages && props.authUser && props.authUser.email && ! props.authUser.email_verified_at) ?
            <Suspense fallback={ <SuspenseFallback /> }>
              <VerifyEmailAlert />
            </Suspense>
            :
            null
        }
        <Suspense fallback={ <SuspenseFallback /> }>
          <Switch>
            <Route exact={ false } path="/:lang/my-account" component={ MyAccount } />
            <Route exact={ true } path="/:lang/login" component={ Login } />
            <Route exact={ true } path="/:lang/register" component={ Register } />
            <Route exact={ true } path="/:lang/register/with-mobile-phone" component={ RegisterPhone } />
            <Route exact={ true } path="/:lang/forgot-password" component={ ForgotPassword } />
            <Route exact={ false } path="/:lang/reset-password" component={ ResetPassword } />
            <Route exact={ false } path="/:lang/reset-password-otp" component={ ResetPasswordOtp } />
            <Route exact={ false } path="/:lang/verify-email" component={ VerifyEmail } />
            <Route exact={ true } path="/:lang/terms-of-service" component={ TermsOfService } />
            <Route exact={ true } path="/:lang/privacy-policy" component={ PrivacyPolicy } />
            <Route exact={ true } path="/:lang/faq" component={ FAQ } />
            <Route exact={ true } path="/:lang/about" component={ About } />
            <Route exact={ true } path="/:lang/pricing" component={ Pricing } />
            <Route exact={ true } path="/:lang/contact" component={ Contact } />
            <Route exact={ false } path="/:lang/help-center" component={ HelpCenter } />
            <Route exact={ true } path="/:lang/service-categories" component={ ServiceCategories } />
            <Route exact={ false } path="/:lang/business-entities" component={ OrganizationsList } />
            <Route exact={ false } path="/:lang/business-entity/:organizationId/:organizationSlug/:bookingType?" component={ Shopfront } />
            <Route exact={ true }
              path="/:lang"
              render={ (props) =>
                existingLang ?
                  <Home { ...props } />
                  :
                  <Redirect to={ `/${defaultLang}/${lang}` } />
              }
            />
            <Route exact={ true } path="/:lang/forbidden" component={ Forbidden } />
            <Route path="/:lang/404" component={ PageNotFound } />
            <Redirect to={ '/' + (existingLang ? lang : defaultLang) + '/404' } />
          </Switch>
        </Suspense>
      </main>
      <Suspense fallback={ <SuspenseFallback /> }>
        <Footer />
        <ErrorsAlert errors={ props.formErrors } />
        <ImageMagnifierPopup />
        <Modal modalId="reload-modal"
          isConfirm={ true }
          okButtonText="refresh-now"
          cancelButtonText="not-now"
          confirmCallback={ ServiceWorkerRegistration.reloadAfterUpdate }
        />
        <Modal />
        {
          (window.localStorage.getItem('agreed-to-cookies') === 'yes') ?
            null
            :
            <CookieNotification />
        }
        {
          (props.isPwaInstalled) ?
            null
            :
            <InstallPWAPrompt />
        }
      </Suspense>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  const shouldDisplayPageTitle =
    (
      (
        state.isPwaInstalled
        &&
        state.mediaBreakpoint > state.mediaBreakpoints.sm.width
      )
      ||
      ! state.isPwaInstalled
    )
    &&
    (
      state.pageTitle
      &&
      ! state.hidePageTitle
    );

  return {
    mediaBreakpoints: state.mediaBreakpoints,
    mediaBreakpoint: state.mediaBreakpoint,
    isPwaInstalled: state.isPwaInstalled,
    hidePageTitle: state.hidePageTitle,
    pageTitle: state.pageTitle,
    loggedIn: state.loggedIn,
    authUser: state.authUser,
    formFlashes: state.formFlashes,
    formErrors: state.formErrors,
    shouldDisplayPageTitle: shouldDisplayPageTitle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLang: (lang) => dispatch(setLang(lang)),
    getAuthenticatedUser: () => dispatch(getAuthenticatedUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppSwitch);
